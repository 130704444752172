import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import LightBox from '../components/lightbox'


let ProjectTemplate = ({ data: { previous, next, post }, location }) => {

  let {
    title,
    content,
    seo,
    featuredImage: {
      node: {
        altText,
        localFile: {
          childImageSharp: { gatsbyImageData },
        },
      },
    },
    acf_projects: { projectDetails: details, video },
    contentTypeName,
    slug,
  } = post



  // console.log(video)
location = { ...location, pathname: "/" }
  

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />
      <section className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{parse(content)}</div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          {!!details &&
            details.map((post, index) => {
              return (
                <div className="row post mb-5" key={index}>
                  <div className="col-md-3">
                    <h2>{post.title}</h2>
                  </div>
                  <div className="col-md-7">
                    <p>{parse(post.description)}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </section>
      <section className="lightbox pt-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="thumb">
                {!!gatsbyImageData && (
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={altText}
                    loading="eager"
                  />
                )}
                {!!video && (
                  <LightBox
                    title={title}
                    type="video"
                    url={video}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {!!previous && (
              <div className="col">
                <Link
                  to={previous.uri}
                  title={previous.title}
                  className="link arrow-left"
                >
                  Previous project
                </Link>
              </div>
            )}
            {!!next && (
              <div className="col text-end">
                <Link to={next.uri} title={next.title} className="link arrow">
                  Next project
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpProject(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_projects {
        projectDetails {
          title
          description
        }
        video
      }
      seo {
        ...WpYoastSEO
      }
      contentTypeName
      slug
    }

    # this gets us the previous post by id (if it exists)
    previous: wpProject(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpProject(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
