import React, { Component } from "react"
import ReactImageVideoLightbox from "react-image-video-lightbox";

export default class LightBox extends Component {
  state = {
    lightboxOpen: false,
  }

  
  render() {
    const data = this.props;
    // console.log(data)
    return (
      <>
          <button
            style={{
              border: "none",
            }}
            onClick={() => this.setState({ lightboxOpen: true })}
          >
            <span></span>
          </button>
        {this.state.lightboxOpen && (
          <ReactImageVideoLightbox
          data={[data]}
          startIndex={0}
          cssClass="lightbox"
          onCloseCallback={() => this.setState({ lightboxOpen: false })}
          onNavigationCallback={currentIndex =>
              console.log(`Current index: ${currentIndex}`)
            }
            />
            )}
      </>
    )
  }
}

